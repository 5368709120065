<template>
    <div>
        <div>
            <breadcrumb
                name="Add Extended"
                title="Category Details"
                subtitle="Add New Content"
                @onTitleClicked="goBack"
            />
        </div>
        <div class="body">
            <vs-row style="margin-left: 20px; margin-right: 20px;">
                <vs-col vs-lg="6" vs-md="12">
                    <vs-input
                        label="Title English"
                        v-model="name_en"
                        size="medium"
                        class="input"
                        danger-text="This field is mandatory"
                    />
                </vs-col>
                <vs-col vs-lg="6" vs-md="12">
                    <vs-input
                        label="Title Arabic"
                        v-model="name_ar"
                        size="medium"
                        class="input"
                        danger-text="This field is mandatory"
                    />
                </vs-col>
            </vs-row>

            <vs-row style="margin-left: 20px; margin-right: 20px;" class="mt-5">
                <vs-col vs-lg="6" vs-md="12" class="pr-13">
                    <small>English Description *</small>
                    <ckeditor
                        :editor="editor"
                        v-model="desc_en"
                        :config="editorConfigEn"
                        class="input"
                    />
                </vs-col>
                <vs-col vs-lg="6" vs-md="12" class="pr-13">
                    <small>Arabic Description *</small>
                    <ckeditor
                        :editor="editor"
                        v-model="desc_ar"
                        :config="editorConfigAr"
                        class="input"
                    />
                </vs-col>
            </vs-row>

            <div class="optionBtns">
                <vs-button
                    icon-pack="feather"
                    icon="icon-save"
                    :disabled="!invalidContent"
                    @click="confirmSaveContent"
                >Save</vs-button>
                <vs-button type="border" class="ml-5 mr-10" color="primary" @click="goBack">Cancel</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import ChooseModel from "../../../components/custom/choose-model";
import Breadcrumb from "../../../components/general/breadcrumb";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadFile from "../../../components/uploader/upload-file";

export default {
    name: "add-extended-content",
    components: { ClassicEditor, Breadcrumb, ChooseModel, UploadFile },
    data() {
        return {
            id: this.$route.params.id,
            name_en: "",
            name_ar: "",
            desc_en: "",
            desc_ar: "",
            editor: ClassicEditor,
          editorConfigAr: {
            placeholder: 'تفاصيل',
            language: 'ar'
          },
          editorConfigEn: {
            placeholder: 'Details',
            language: 'en'
          },
        };
    },
    methods: {
        confirmSaveContent() {
            this.$vs.dialog({
                type: "confirm",
                color: "primary",
                title: `Confirm`,
                text: "You're going to save this Content!",
                accept: () => this.saveContent()
            });
        },
        saveContent() {
            this.$vs.loading();

            let formData = new FormData();

            formData.append("warranty_category_id", this.id);
            formData.append("name_en", this.name_en);
            formData.append("name_ar", this.name_ar);
            formData.append("desc_en", this.desc_en);
            formData.append("desc_ar", this.desc_ar);

            this.$http
                .post("warranties/add-warranty-item", formData)
                .then(r => {
                    this.$vs.loading.close();
                    console.log(r);
                    this.$vs.notify({
                        title: "Success",
                        color: "success",
                        text: "Content is added successfully!"
                    });
                    this.$router.push(`/extended-warranties/${this.id}`);
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        color: "danger",
                        text: "Couldn't Save Content!"
                    });
                });
        },
        goBack() {
            this.$router.go(-1);
        }
    },
    computed: {
        invalidContent() {
            return (
                this.id &&
                this.name_ar &&
                this.name_en &&
                this.desc_ar &&
                this.desc_en
            );
        }
    },
    mounted() {}
};
</script>

<style scoped lang="scss">
.clr {
    clear: both;
}

.body {
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;

    .drop-down {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    label {
        font-size: 1rem;
        padding: 5px;
    }

    select {
        padding: 10px;
        border-radius: 5px;
        width: 90%;
        background-color: #fff;
        border: 1px solid #00000030;
    }

    .inputs {
        margin: 20px 0 20px 0;
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
    }
}

.input {
    width: 90%;
    margin-top: 30px;
}

.switch {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
}

.checkboxes {
    margin: 10px;

    .checkbox {
        margin: 5px;
    }
}

.optionInput {
    margin-top: 30px;
    width: 50%;
    display: block;
    padding: 10px;
    border-radius: 5px;
    color: #00000090;
    margin-left: 10px;
}

h2 {
    margin: 30px;
}

.optionBtns {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

h4 {
    padding: 20px;
}

.subtitle {
    margin-top: 50px;
    color: #ccc;
}

.input {
    width: 90%;
    margin-top: 10px;
}
</style>
